<template>
  <div class="additional-notes-sec">
    <spinner :show="loading" :use-modal="false" :message="spinnerMessage"></spinner>
    <div class="box-wrapper pb-3">
      <h2 class="sub-head text-center mb-3">Notes</h2>
      <form class="notes">
        <div class="row notes">
          <visit-warning v-if="editedNote && location.id" :property-id="location.id" :context="context"></visit-warning>
          <entry-note v-if="note && !loading" :note="note" :booking="booking" :location-id="location.id" note-key="instructions" class="col-md-6 offset-md-3 col-sm-12 text-center mb-3"></entry-note>
          <note v-if="note && !loading" :note="note" :booking="booking" note-key="bedrooms" class="col-sm-6 text-center mb-3"></note>
          <note v-if="note && !loading" :note="note" :booking="booking" note-key="bathrooms" class="col-sm-6 text-center mb-3"></note>
          <note v-if="note && !loading" :note="note" :booking="booking" note-key="kitchen" class="col-sm-6 text-center mb-3"></note>
          <note v-if="note && !loading" :note="note" :booking="booking" note-key="special" class="col-sm-6 text-center mb-3"></note>
        </div>
      </form>
      <customized-visits :visits="visits"></customized-visits>
    </div>
  </div>
</template>
<script>
import Spinner from '@/shared/spinner.vue'
import Note from './note2.vue'
import EntryNote from './entry_note.vue'
import TextOnlyNote from './text_only_note.vue'
import api from '@/clients/api'
import VisitWarning from '@/shared/visit_warning.vue'
import CustomizedVisits from './customized_visits.vue'
import $ from 'jquery'

export default {
  props: {
    booking: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      required: true
    },
    location: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  components: { Note, Spinner, VisitWarning, TextOnlyNote, EntryNote, CustomizedVisits },
  data() {
    return {
      loading: false,
      note: null,
      editedNote: null,
      spinnerMessage: 'Loading'
    }
  },
  computed: {
    noteId() {
      if (this.note) {
        return this.note.id
      }
      if (this.location) {
       return this.location.note_id
      }
    },
    visits() {
      if (this.location) {
        if (this.location.visits_with_custom_notes) {
          return this.location.visits_with_custom_notes
        }
      }
      return []
    },
    locked() {
      return this.readonly === true
    },
  },
  mounted() {
    if (this.noteId == null) {
      api.createNote().then(response => {
        this.note = response.data.notes
        api.updateProperty(this.location.id, { note_id: this.note.id })
      })
    } else {
      this.loadNote()
    }
  },
  methods: {
    loadNote() {
      this.loading = true
      api.getNote(this.noteId).then(response => {
        this.note = response.data.notes
        this.editedNote = null
        this.$emit('update:edit-mode', false)
        this.loading = false
      })
    },
    saveNote(newNote) {
      this.loading = true
      this.spinnerMessage = 'Saving'
      if (this.booking) {
        newNote.booking = 1
      }
      api.saveNote(this.noteId, newNote).then(response => {
        this.loadNote()
      }, response => {
        this.loading = false
        this.$flasher.error(response)
      })
    },
    setNoteEdited(noteKey) {
      this.editedNote = noteKey
      this.$emit('update:edit-mode', noteKey != null)
    }
  },
  watch: {
    readonly(newValue) {
      if (newValue === true) {
        this.loadNote()
      }
    }
  }
}
</script>
