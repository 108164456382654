<template>
  <div class="note mb-2" v-bind="$attrs">
    <div class="text-center">
      <img class="mw-120" :src="iconUrl" :alt="noteKey">
      <p class="text-head">{{title}}</p>
    </div>
    <div class="position-relative">
      <p class="edit-attach-text" v-if="inReadOnlyMode">{{ body ? body : 'Notes...' }}</p>
      <!-- <textarea v-if="inReadOnlyMode" rows="1" placeholder="Notes..." readonly="readonly" class="form-control" :value="body"></textarea> -->
      <textarea v-if="inEditMode" rows="3" placeholder="Notes..." class="form-control" v-model="newContent"></textarea>
      <div class="edit-attach-set" v-if="enabled">
        <a v-if="inEditMode" href="#" @click.prevent="cancel"><img width="40" src="@images/close-icon.png" alt="Close icon"></a>
        <a v-if="inEditMode" href="#" @click.prevent="ok"><img width="40" src="@images/ok-icon@2x.png" alt="Save"></a>

        <a v-if="inReadOnlyMode && isInactive" href="#" @click.prevent="edit"><img width="40" src="@images/edit-icon02.png" alt="Edit icon02"></a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  inheritAttrs: false,
  props: {
    note: {
      type: Object,
      required: true
    },
    noteKey: {
      type: String,
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      modeValue: '',
      newContent: this.note[this.noteKey].body
    }
  },
  computed: {
    isInactive() {
      return this.$parent.editedNote == null
    },
    isActive() {
      return this.$parent.editedNote == this.noteKey
    },
    noteData() {
      if (this.note) {
        return this.note[this.noteKey]
      }
      return {}
    },
    body() {
      return this.noteData.body
    },
    iconUrl() {
      return this.noteData.icon_url
    },
    mode: {
      set(v) {
        this.modeValue = v
      },
      get() {
        return this.modeValue
      }
    },
    inEditMode() {
      return (this.mode == 'edit') && (this.isActive)
    },
    inReadOnlyMode() {
      return this.mode == ''
    },
    title() {
      return this.noteData.title
    },
    params() {
      var params = {}
      params[this.noteKey + '_body'] = this.newContent
      return params
    }
  },
  mounted() {},
  methods: {
    cancel() {
      this.newContent = this.body
      this.modeValue = ''
      this.$parent.setNoteEdited(null)
    },
    edit() {
      this.modeValue = 'edit'
      this.$parent.setNoteEdited(this.noteKey)
    },
    ok() {
      this.modeValue = ''
      this.$parent.saveNote(this.params)
    },
  }
}
</script>
