<template>
  <div v-if="visits.length > 0">
    <hr />
    <h3 class="sub-head text-center mb-3">Visits with customized notes</h3>
    <p class="text-center my-0">
      <a class="blue-text my-0" v-b-toggle.customizedNotes>
        <i class="fal fa-chevron-down"></i>
      </a>
    </p>
    <div class="col-12">
      <b-collapse id="customizedNotes">
        <ul class="list-group list-group-flush">
          <li class="list-group-item w-200 mw-200 mx-auto p-2" v-for="(visit, idx) in visits">
            <div class="d-flex">
              <div class="mr-auto">{{visit.start}}</div>
              <div>
                <i class="blue-text mr-3 cursor-pointer fal fa-eye" @click="viewVisit(visit)"></i>
                <i v-if="!locked" class="red-text cursor-pointer fal fa-times" @click="deleteVisit(idx)"></i>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="!locked" class="btn-set">
          <button class="btn btn-red btn-mini main-btn mt-4" @click="deleteVisits">
            Clear all
          </button>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visits: {
      type: Array
    },
  },
  computed: {
    locked() {
      return this.$parent.locked
    }
  },
  methods: {
    viewVisit(visit) {
      this.$parent.$emit('open-visit', visit.id)
    },
    deleteVisit(visitIdx) {
      this.loading = true
      this.$dialog.confirm('Are you sure you want to delete this customized notes?', { okText: 'Yes', cancelText: 'No' }).then((dialog) => {
        api.deleteVisitNote(this.visits[visitIdx].id).then(response => {
          this.loading = false
          this.$parent.$emit('reload:property')
        })
      })
    },
    deleteVisits() {
      this.$dialog.confirm('Are you sure you want to delete the list of these personalized notes?', { okText: 'Yes', cancelText: 'No' }).then((dialog) => {
        api.deleteVisitNotesForProperty(this.location.id).then(() => {
          this.$parent.$emit('reload:property')
        })
      })
    }
  }
}
</script>
