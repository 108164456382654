<template>
  <div class="note mb-2" v-bind="$attrs">
    <div class="text-center">
      <img class="mw-120" :src="iconUrl" :alt="noteKey">
      <p class="text-head">{{title}}</p>
    </div>
    <div class="col-12">
      <p key="notes" class="edit-attach-text" v-show="inReadOnlyMode">{{ body ? body : 'Notes...' }}</p>
      <ol class="list-unstyled" key="codes" v-show="inReadOnlyMode">
        <li v-if="hasGateCode" class="border-bottom"><strong>Code:</strong> {{note.gate_code}}</li>
        <li v-if="hasAlarmCode" class="border-bottom"><strong>Alarm Code:</strong> {{note.alarm_code}}</li>
        <li v-if="hasLockbox" class="border-bottom"><strong>Keypad / Lockbox:</strong> {{note.lockbox}}</li>
      </ol>
      <div v-show="inEditMode" key="forEdit">
        <note-text v-show="inEditMode" v-model="newContent" :rules="textFieldRules" placeholder="Notes..." name="entryNote"></note-text>
        <input v-if="inEditMode" class="form-control" v-model="newGate" placeholder="Gate code"></input>
        <input v-if="inEditMode" class="form-control" v-model="newAlarm" placeholder="Alarm code"></input>
        <input v-if="inEditMode" class="form-control" v-model="newLockbox" placeholder="Keypad / Lockbox"></input>
      </div>
      <a v-if="inUploadMode" href="#" @click.prevent="cancel">Cancel Upload</a>
      <div class="edit-attach-set" v-if="enabled && canEditNote && !locked">
        <a v-if="inEditMode" href="#" @click.prevent="cancel"><img width="40" src="@images/close-icon.png" alt="Close icon"></a>
        <a v-if="inEditMode" href="#" @click.prevent="ok"><img width="40" src="@images/ok-icon@2x.png" alt="Save"></a>

        <a v-if="inReadOnlyMode && isInactive && !locked" href="#" @click.prevent="edit"><img width="40" src="@images/edit-icon02.png" alt="Edit icon02"></a>
        <a v-if="inReadOnlyMode && isInactive && !locked" href="#" @click.prevent="upload"><img width="40" src="@images/attach-icon02.png" alt="Attach icon02"></a>
      </div>
      <note-image-upload v-if="inUploadMode" :note-key="noteKey" :reservation-id="reservationId" :booking="booking" file-field="attachment1" :attachment="attachment1" :url="noteUploadUrl" @update="onUpload"></note-image-upload>
      <note-image-upload v-if="inUploadMode" :note-key="noteKey" :reservation-id="reservationId" :booking="booking" file-field="attachment2" :attachment="attachment2" :url="noteUploadUrl" @update="onUpload"></note-image-upload>
    </div>
    <p v-if="inReadOnlyMode" class="my-3">
      <a v-if="attachment1" target="_blank" :href="attachment1.url" class="note-img m-3 js-img"><img :src="attachment1.thumb.url"></a>
      <a v-if="attachment2" target="_blank" :href="attachment2.url" class="note-img m-3 js-img"><img :src="attachment2.thumb.url"></a>
    </p>
  </div>
</template>
<script>

import Note2 from './note2.vue'
import NoteText from '@/shared/inputs/text_area.vue'
export default {
  extends: Note2,
  props: {
    locationId: {
      type: Number
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  components: { NoteText },
  data() {
    return {
      newGate: this.note.gate_code,
      newAlarm: this.note.alarm_code,
      newLockbox: this.note.lockbox,
    }
  },
  computed: {
    hasGateCode() {
      return this.newGate && (this.newGate.length > 0)
    },
    hasAlarmCode() {
      return this.newAlarm && (this.newAlarm.length > 0)
    },
    hasLockbox() {
      return this.newLockbox && (this.newLockbox.length > 0)
    },
    textFieldRules() {
      if (this.required) {
        return 'required|min:10'
      }
    },
    params() {
      var params = {
        gate_code: this.newGate,
        alarm_code: this.newAlarm,
        lockbox: this.newLockbox
      }
      params[this.noteKey + '_body'] = this.newContent
      return params
    }
  },
  methods: {
    ok() {
      this.modeValue = ''
      this.$parent.saveNote(this.params)
    },
  }
}
</script>
